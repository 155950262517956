import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const AzeIcon = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1953_4640)">
        <path d="M24 0.000488281H0V24H24V0.000488281Z" fill="#D80027" />
        <path d="M24 0H0V7.99983H24V0Z" fill="#338AF3" />
        <path d="M24 16H0V23.9998H24V16Z" fill="#6DA544" />
        <path
          d="M12.25 15.2501C10.455 15.2501 8.99992 13.795 8.99992 12C8.99992 10.2051 10.455 8.75 12.25 8.75C12.8096 8.75 13.3361 8.89152 13.796 9.14061C13.0747 8.43533 12.0884 8 11 8C8.79086 8 7 9.79086 7 12C7 14.2092 8.79086 16.0001 11 16.0001C12.0884 16.0001 13.0747 15.5647 13.796 14.8595C13.3361 15.1085 12.8096 15.2501 12.25 15.2501Z"
          fill="#F0F0F0"
        />
        <path
          d="M14.75 9.75L15.1805 10.9607L16.3411 10.409L15.7893 11.5695L17 12L15.7893 12.4305L16.3411 13.591L15.1805 13.0393L14.75 14.25L14.3196 13.0393L13.159 13.591L13.7107 12.4305L12.5 12L13.7107 11.5695L13.159 10.409L14.3196 10.9607L14.75 9.75Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1953_4640">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AzeIcon;
