import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const PlusCricleIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 0.833252C4.93755 0.833252 0.833496 4.93731 0.833496 9.99992C0.833496 15.0625 4.93755 19.1666 10.0002 19.1666C15.0628 19.1666 19.1668 15.0625 19.1668 9.99992C19.1668 4.93731 15.0628 0.833252 10.0002 0.833252ZM10.0002 5.83325C10.4604 5.83325 10.8335 6.20635 10.8335 6.66658V9.16658H13.3335C13.7937 9.16658 14.1668 9.53968 14.1668 9.99992C14.1668 10.4602 13.7937 10.8333 13.3335 10.8333H10.8335V13.3333C10.8335 13.7935 10.4604 14.1666 10.0002 14.1666C9.53992 14.1666 9.16683 13.7935 9.16683 13.3333V10.8333H6.66683C6.20659 10.8333 5.8335 10.4602 5.8335 9.99992C5.8335 9.53968 6.20659 9.16658 6.66683 9.16658H9.16683V6.66658C9.16683 6.20635 9.53992 5.83325 10.0002 5.83325Z"
      />
    </svg>
  );
};

export default PlusCricleIcon;
