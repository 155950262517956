import Icon from "@/components/Icons";

const DemoButton = () => {
  return (
    <button className="bg-demoButtonBorder p-[2px] rounded-full hover:bg-demoButton h-11">
      <div className="flex gap-2 items-center  h-full w-full px-4 py-3 rounded-full bg-demoButton hover:bg-demoButtonBorder">
        <Icon name="zap" />

        <span className="font-medium text-base text-light-source-white dark:text-dark-source-white">
          DEMO
        </span>
      </div>
    </button>
  );
};

// border: 2px solid;

// border-image-source: linear-gradient(82deg, #422FD2 0%, #8E11FA 100%);

// background: linear-gradient(82deg, #422FD2 0%, #8E11FA 100%),
// linear-gradient(82deg, #8E11FA 0%, #422FD2 100%);

export default DemoButton;
