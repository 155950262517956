import eventBus from "@/EventBus";
import Toast from "@/components/Toast";
import { AnimatePresence } from "framer-motion";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { v4 as uuid } from "uuid";

type ToastType = "success" | "error" | "warning" | "info";

export type ToastTypeParams = {
  message: string;
  type?: ToastType;
  timeout?: number;
  id: number;
};

type ToastContextType = {
  show: (content: Omit<ToastTypeParams, "id">) => void;
};

type ToastProviderProps = PropsWithChildren;

export const ToastContext = createContext<ToastContextType>({
  show: () => {},
});

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Array<ToastTypeParams>>([]);

  useEffect(() => {
    eventBus.on("toast", (data: ToastTypeParams & { id: number }) => {
      setToasts((prev) => [...prev, data]);

      setTimeout(() => {
        setToasts((prev) => prev.filter((toast) => toast.id !== data.id));
      }, data.timeout);
    });

    return () => {
      eventBus.removeAllListeners("toast");
    };
  }, [setToasts]);

  const showToast = ({
    message,
    type = "success",
    timeout = 3000,
  }: Omit<ToastTypeParams, "id">) => {
    const id = uuid();

    eventBus.emit("toast", { message, type, timeout, id });
  };

  return (
    <ToastContext.Provider
      value={{
        show: showToast,
      }}
    >
      {createPortal(
        <div className="fixed top-0 left-0 flex justify-start w-full h-full pt-[120px] items-center pointer-events-none flex-col gap-5">
          <AnimatePresence>
            {toasts.map((toast, index) => {
              return <Toast {...toast} key={index} />;
            })}
          </AnimatePresence>
        </div>,
        document.body
      )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
