import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const HomeIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4355 1.11359C10.1503 1.03636 9.84962 1.03636 9.56437 1.11359C9.23324 1.20325 8.95445 1.42213 8.73194 1.59682L8.66975 1.6455L2.95307 6.09173C2.63921 6.3353 2.3627 6.54988 2.15665 6.82847C1.97583 7.07295 1.84112 7.34838 1.75915 7.64121C1.66575 7.97489 1.66613 8.3249 1.66656 8.72218L1.66662 14.8654C1.6666 15.3047 1.66659 15.6837 1.69209 15.9958C1.71901 16.3253 1.77844 16.6529 1.9391 16.9683C2.17879 17.4387 2.56124 17.8211 3.03164 18.0608C3.34696 18.2215 3.67464 18.2809 4.0041 18.3078C4.31624 18.3333 4.6952 18.3333 5.13448 18.3333H14.8654C15.3047 18.3333 15.6837 18.3333 15.9958 18.3078C16.3253 18.2809 16.6529 18.2215 16.9683 18.0608C17.4387 17.8211 17.8211 17.4387 18.0608 16.9683C18.2215 16.6529 18.2809 16.3253 18.3078 15.9958C18.3333 15.6837 18.3333 15.3047 18.3333 14.8654L18.3333 8.72219C18.3338 8.3249 18.3342 7.97489 18.2408 7.64121C18.1588 7.34838 18.0241 7.07295 17.8433 6.82847C17.6372 6.54988 17.3607 6.33529 17.0468 6.09172L11.3302 1.6455L11.268 1.59682C11.0455 1.42213 10.7667 1.20325 10.4355 1.11359ZM7.57867 11.4589C7.46395 11.0132 7.00963 10.7449 6.56392 10.8596C6.11821 10.9743 5.84989 11.4286 5.9646 11.8743C6.4271 13.6713 8.05731 15 9.99995 15C11.9426 15 13.5728 13.6713 14.0353 11.8743C14.15 11.4286 13.8817 10.9743 13.436 10.8596C12.9903 10.7449 12.536 11.0132 12.4212 11.4589C12.1437 12.5374 11.1637 13.3333 9.99995 13.3333C8.8362 13.3333 7.85624 12.5374 7.57867 11.4589Z"
      />
    </svg>
  );
};

export default HomeIcon;
