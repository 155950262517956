import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const BellIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49998 17.5C7.49998 17.0398 7.87308 16.6667 8.33332 16.6667H11.6667C12.1269 16.6667 12.5 17.0398 12.5 17.5C12.5 17.9603 12.1269 18.3334 11.6667 18.3334H8.33332C7.87308 18.3334 7.49998 17.9603 7.49998 17.5Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8752 2.54192C6.96916 1.44796 8.45289 0.833374 9.99998 0.833374C11.5471 0.833374 13.0308 1.44796 14.1248 2.54192C15.2187 3.63588 15.8333 5.11961 15.8333 6.66671C15.8333 9.09394 16.4433 10.7033 17.0828 11.731L17.0927 11.7469C17.3904 12.2253 17.6258 12.6036 17.7845 12.8789C17.864 13.0169 17.9365 13.1498 17.9901 13.2679C18.0169 13.3269 18.0468 13.3992 18.0702 13.4778C18.0896 13.543 18.1266 13.6823 18.1128 13.8519C18.1034 13.9659 18.0802 14.1607 17.9684 14.362C17.8566 14.5632 17.7035 14.6858 17.6116 14.754C17.4031 14.9087 17.1635 14.9441 17.0836 14.9559L17.0799 14.9564C16.9571 14.9746 16.8177 14.9837 16.677 14.9892C16.3977 15 16.0109 15 15.5299 15H4.47011C3.98909 15 3.6023 15 3.32292 14.9892C3.1823 14.9837 3.0429 14.9746 2.92006 14.9564L2.91637 14.9559C2.83643 14.9441 2.5969 14.9087 2.38832 14.754C2.29646 14.6858 2.14335 14.5632 2.03156 14.362C1.91976 14.1607 1.89652 13.9659 1.8872 13.8519C1.87332 13.6823 1.91032 13.543 1.92975 13.4778C1.95315 13.3992 1.98311 13.3269 2.00988 13.2679C2.06352 13.1498 2.13596 13.0169 2.21548 12.8789C2.37417 12.6036 2.60957 12.2253 2.90734 11.7468L2.91717 11.731C3.55665 10.7033 4.16665 9.09394 4.16665 6.66671C4.16665 5.11961 4.78123 3.63588 5.8752 2.54192Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default BellIcon;
