import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const UploadIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.6665 2.49984C1.6665 2.0396 2.0396 1.6665 2.49984 1.6665H17.4998C17.9601 1.6665 18.3332 2.0396 18.3332 2.49984C18.3332 2.96007 17.9601 3.33317 17.4998 3.33317H2.49984C2.0396 3.33317 1.6665 2.96007 1.6665 2.49984Z" />
      <path d="M4.41058 11.4224C4.73602 11.7479 5.26366 11.7479 5.58909 11.4224L9.1665 7.84502L9.1665 17.4998C9.1665 17.9601 9.5396 18.3332 9.99984 18.3332C10.4601 18.3332 10.8332 17.9601 10.8332 17.4998L10.8332 7.84502L14.4106 11.4224C14.736 11.7479 15.2637 11.7479 15.5891 11.4224C15.9145 11.097 15.9145 10.5694 15.5891 10.2439L10.5891 5.24391C10.2637 4.91848 9.73602 4.91848 9.41058 5.24391L4.41058 10.2439C4.08514 10.5694 4.08514 11.097 4.41058 11.4224Z" />
    </svg>
  );
};

export default UploadIcon;
