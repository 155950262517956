import Button, { ButtonProps } from "./";
import Icon, { IconName } from "@/components/Icons";

type IconButtonProps = ButtonProps & {
  icon: IconName;
};

const IconButton = (props: IconButtonProps) => {
  const { size = "sm", icon, mode = "default", ...rest } = props;

  return (
    <Button circle size={size} mode={mode} {...rest}>
      <Icon name={icon} />
    </Button>
  );
};

export default IconButton;
