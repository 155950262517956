import { RouterProvider } from "react-router-dom";
import routes from "@/routes";
import ErrorBoundary from "./components/ErrorBoundary";
import { Suspense } from "react";

function App() {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Suspense fallback={<div>Loading</div>}>
        <RouterProvider router={routes} />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
