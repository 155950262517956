import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const LogoutIcon = (props: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill="#F32828"
      {...props}
    >
      <path d="M4.19504 0.990441C4.60799 1.00682 5.11221 1.06286 5.72599 1.13106L6.6431 1.23296C6.85307 1.25625 7.00917 1.27357 7.15 1.30157C8.60536 1.59093 9.69421 2.80747 9.82109 4.28588C9.83336 4.42894 9.83334 4.58599 9.8333 4.79724V5.16665C9.8333 5.63336 9.8333 5.86671 9.74247 6.04497C9.66258 6.20177 9.53509 6.32926 9.37829 6.40915C9.20003 6.49998 8.96668 6.49998 8.49997 6.49998H6.49996C5.11925 6.49998 3.99996 7.61927 3.99996 8.99998C3.99996 10.3807 5.11925 11.5 6.49996 11.5H8.49997C8.96668 11.5 9.20003 11.5 9.37829 11.5908C9.53509 11.6707 9.66258 11.7982 9.74247 11.955C9.8333 12.1332 9.8333 12.3666 9.8333 12.8333V13.2027C9.83334 13.4139 9.83336 13.571 9.82109 13.714C9.69421 15.1925 8.60536 16.409 7.15 16.6984C7.00918 16.7264 6.85307 16.7437 6.64316 16.767L5.75772 16.8653L5.75608 16.8655L5.72591 16.8689C5.11216 16.9371 4.60797 16.9931 4.19503 17.0095C3.76891 17.0264 3.37118 17.0054 2.98403 16.8815C2.081 16.5926 1.34376 15.9327 0.956826 15.0671C0.790939 14.696 0.726188 14.303 0.695933 13.8776C0.666613 13.4654 0.666619 12.9581 0.666626 12.3405V5.6594C0.666619 5.04186 0.666613 4.53452 0.695933 4.12229C0.726188 3.69691 0.790939 3.30392 0.956826 2.93282C1.34376 2.06722 2.081 1.40736 2.98403 1.11838C3.37118 0.994493 3.76892 0.973536 4.19504 0.990441Z" />
      <path d="M11.744 4.24406C12.0695 3.91862 12.5971 3.91862 12.9225 4.24406L17.0892 8.41072C17.4147 8.73616 17.4147 9.2638 17.0892 9.58924L12.9225 13.7559C12.5971 14.0813 12.0695 14.0813 11.744 13.7559C11.4186 13.4305 11.4186 12.9028 11.744 12.5774L14.4881 9.83331H6.49996C6.03972 9.83331 5.66663 9.46022 5.66663 8.99998C5.66663 8.53974 6.03972 8.16665 6.49996 8.16665H14.4881L11.744 5.42257C11.4186 5.09713 11.4186 4.56949 11.744 4.24406Z" />
    </svg>
  );
};

export default LogoutIcon;
