import Button from "@/components/Form/Button";
import Input from "@/components/Form/Input";
import { loginService } from "@/services/auth";
import { setTokens } from "@/utils/auth";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

type Inputs = {
  username: string;
  password: string;
};

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    values: {
      username:
        process.env.NODE_ENV === "development" ? "admin@onsual.com" : "",
      password: process.env.NODE_ENV === "development" ? "102030102030**" : "",
    },
  });
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationKey: "login",
    mutationFn: loginService,
    onError: (error: AxiosError<Error>) => {
      setError("password", {
        type: "manual",
        message: error?.response?.data.detail || "Something went wrong",
      });
    },
    onSuccess: (data) => {
      setTokens(data);

      navigate("/");
    },
  });

  const _handleSubmit = (values: Inputs) => {
    mutate(values);
  };

  return (
    <form onSubmit={handleSubmit(_handleSubmit)} autoComplete="off">
      <div className="flex flex-col gap-2">
        <h5 className="text-3xl font-medium text-light-utility-high dark:text-dark-utility-high">
          Hesabınıza daxil olun
        </h5>

        <div className="flex gap-2 text-lg">
          <span className="text-light-utility-medium dark:text-dark-utility-medium">
            Hesabın yoxdu?
          </span>
          <Link
            to="/email-verification"
            className="text-light-brand-primary dark:text-dark-brand-primary"
          >
            Qeydiyyatdan keç
          </Link>
        </div>
      </div>
      <div className="mt-6">
        <Input
          className="mb-4"
          error={errors.username}
          placeholder="Username"
          {...register("username", {
            required: true,
          })}
        />
        <Input
          type="password"
          className="mb-3"
          error={errors.password}
          placeholder="Password"
          {...register("password", {
            required: true,
          })}
        />
        <div className="mb-6">
          <Link
            to="/forgot-password"
            className="text-lg text-light-brand-primary dark:text-dark-brand-primary"
          >
            Şifrəni unutdun?
          </Link>
        </div>
        <Button
          fullWidth
          disabled={isLoading || !isValid}
          className="mb-4"
          loading={isLoading}
        >
          Login
        </Button>
        <span className="text-lg text-light-utility-medium dark:text-dark-utility-medium">
          “Daxil ol” hissəsinə klik etdikdə bizim Şərtlər və Qaydalar ilə
          razılaşmış olursunuz
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
