import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const MoonIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61625 2.42808C9.95746 2.2336 10.1166 1.82728 9.99815 1.45282C9.87973 1.07836 9.51592 0.837407 9.12494 0.874479C4.4724 1.31561 0.833374 5.23227 0.833374 9.99986C0.833374 15.0625 4.93743 19.1665 10 19.1665C14.7674 19.1665 18.6839 15.5279 19.1253 10.8757C19.1625 10.4847 18.9215 10.1209 18.5471 10.0024C18.1726 9.88399 17.7663 10.0431 17.5718 10.3843C16.5666 12.1476 14.6713 13.3334 12.5 13.3334C9.27838 13.3334 6.66671 10.7217 6.66671 7.50006C6.66671 5.32868 7.85271 3.43325 9.61625 2.42808Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default MoonIcon;
