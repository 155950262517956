import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const RusIcon = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1953_4648)">
        <path d="M24 0H0V24H24V0Z" fill="#0052B4" />
        <path d="M24 0H0V7.99983H24V0Z" fill="#F0F0F0" />
        <path d="M24 16H0V23.9998H24V16Z" fill="#D80027" />
      </g>
      <defs>
        <clipPath id="clip0_1953_4648">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RusIcon;
