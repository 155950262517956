import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const CheckIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2559 4.41107C17.5814 4.73651 17.5814 5.26414 17.2559 5.58958L8.08926 14.7562C7.76382 15.0817 7.23618 15.0817 6.91074 14.7562L2.74408 10.5896C2.41864 10.2641 2.41864 9.73651 2.74408 9.41107C3.06951 9.08563 3.59715 9.08563 3.92259 9.41107L7.5 12.9885L16.0774 4.41107C16.4028 4.08563 16.9305 4.08563 17.2559 4.41107Z"
      />
    </svg>
  );
};

export default CheckIcon;
