import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ChevronDown = (props: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#62717C"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41009 6.91058C4.73553 6.58514 5.26317 6.58514 5.5886 6.91058L9.99935 11.3213L14.4101 6.91058C14.7355 6.58514 15.2632 6.58514 15.5886 6.91058C15.914 7.23602 15.914 7.76366 15.5886 8.08909L10.5886 13.0891C10.2632 13.4145 9.73553 13.4145 9.41009 13.0891L4.41009 8.08909C4.08466 7.76366 4.08466 7.23602 4.41009 6.91058Z"
    />
  </svg>
);

export default ChevronDown;