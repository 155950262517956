import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const CloseIcon = (props: Props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0893 5.58909C16.4148 5.26366 16.4148 4.73602 16.0893 4.41058C15.7639 4.08514 15.2363 4.08514 14.9108 4.41058L10.5001 8.82133L6.08934 4.41058C5.7639 4.08514 5.23626 4.08514 4.91083 4.41058C4.58539 4.73602 4.58539 5.26366 4.91083 5.58909L9.32157 9.99984L4.91083 14.4106C4.58539 14.736 4.58539 15.2637 4.91083 15.5891C5.23626 15.9145 5.7639 15.9145 6.08934 15.5891L10.5001 11.1783L14.9108 15.5891C15.2363 15.9145 15.7639 15.9145 16.0893 15.5891C16.4148 15.2637 16.4148 14.736 16.0893 14.4106L11.6786 9.99984L16.0893 5.58909Z"
      />
    </svg>
  );
};

export default CloseIcon;
