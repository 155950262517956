import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ClosedEye = (props: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.91094 1.91083C2.23637 1.58539 2.76401 1.58539 3.08945 1.91083C8.09695 6.91833 13.0865 11.9079 18.0894 16.9108C18.4149 17.2363 18.4149 17.7639 18.0894 18.0893C17.764 18.4148 17.2364 18.4148 16.9109 18.0893L14.2828 15.4612C13.0788 16.1658 11.6432 16.6668 10.0002 16.6668C7.61546 16.6668 5.6713 15.6128 4.23081 14.4106C2.79226 13.21 1.80599 11.8215 1.31203 11.0394L1.29196 11.0077C1.19393 10.8534 1.06665 10.6531 1.00232 10.3724C0.950382 10.1457 0.950399 9.85423 1.00237 9.62759C1.06674 9.34685 1.19437 9.14601 1.29266 8.99135L1.31289 8.95945C1.81487 8.16482 2.83428 6.72947 4.32965 5.50805L1.91094 3.08934C1.5855 2.7639 1.5855 2.23626 1.91094 1.91083ZM7.12846 8.30686C6.83533 8.80327 6.66686 9.38266 6.66686 10C6.66686 11.841 8.15924 13.3334 10.0002 13.3334C10.6176 13.3334 11.197 13.1649 11.6933 12.8718L10.432 11.6104C10.2944 11.6471 10.1497 11.6667 10.0002 11.6667C9.07972 11.6667 8.33353 10.9205 8.33353 10C8.33353 9.85045 8.35308 9.70576 8.38981 9.56821L7.12846 8.30686Z"
      fill="#62717C"
    />
    <path
      d="M13.3271 9.79145C13.2237 8.11669 11.8836 6.7766 10.2088 6.67317L7.32654 3.79089C8.14288 3.50589 9.03549 3.33341 10.0002 3.33341C12.3849 3.33341 14.3291 4.38735 15.7696 5.58961C17.2081 6.79023 18.1944 8.17865 18.6884 8.96079L18.7084 8.99243C18.8064 9.14672 18.9337 9.34704 18.998 9.62772C19.05 9.85435 19.05 10.1458 18.998 10.3724C18.9337 10.6531 18.8064 10.8534 18.7084 11.0077L18.6884 11.0394C18.3354 11.5982 17.7312 12.4665 16.8903 13.3546L13.3271 9.79145Z"
      fill="#62717C"
    />
  </svg>
);

export default ClosedEye;
