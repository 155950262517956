import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ZapIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.833374C4.93743 0.833374 0.833374 4.93743 0.833374 10C0.833374 15.0626 4.93743 19.1667 10 19.1667C15.0626 19.1667 19.1667 15.0626 19.1667 10C19.1667 4.93743 15.0626 0.833374 10 0.833374ZM6.13467 10.2057L10 4.58337V8.75004H13.316C13.7267 8.75004 13.9321 8.75004 14.0441 8.83549C14.1416 8.90991 14.2013 9.02352 14.2074 9.14603C14.2144 9.2867 14.098 9.45593 13.8654 9.79439L10 15.4167V11.25H6.68404C6.2733 11.25 6.06794 11.25 5.95598 11.1646C5.85847 11.0902 5.79871 10.9766 5.79262 10.854C5.78564 10.7134 5.90198 10.5441 6.13467 10.2057Z"
        fill="white"
      />
    </svg>
  );
};

export default ZapIcon;
