import useAuth from "@/hooks/useAuth";

const Profile = () => {
  const { user } = useAuth();

  return (
    <div className="flex gap-3 px-4 py-3 transition bg-light-surface-medium dark:bg-dark-surface-medium rounded-xl">
      <div className="flex items-center justify-center w-10 h-10 transition rounded-full bg-light-surface-low dark:bg-dark-surface-low">
        <span className="font-medium transition text-light-utility-medium dark:text-dark-utility-medium">
          {user?.name[0]} {user?.surname[0]}
        </span>
      </div>

      <div>
        <h3 className="text-base font-medium transition text-light-utility-high dark:text-dark-utility-high">
          {user?.name} {user?.surname}
        </h3>
        <p className="text-base font-normal transition text-light-utility-medium dark:text-dark-utility-medium">
          {user?.companyName}
        </p>
      </div>
    </div>
  );
};

export default Profile;
