import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import AuthProvider from "./AuthProvider";
import ToastProvider from "./ToastProvider";

export const queryClient = new QueryClient();

const LayoutProviders = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </ToastProvider>
    </QueryClientProvider>
  );
};

export default LayoutProviders;
