import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import Icon from "../Icons";
import { ToastTypeParams } from "@/providers/ToastProvider";
import clsx from "clsx";

type ToastProps = Omit<ToastTypeParams, "timeout">;

const Toast = (props: ToastProps) => {
  const { message, type } = props;

  const classes = clsx(
    "py-1 px-1 rounded-full w-max z-[9999] flex items-center max-h-[44px]",
    {
      "bg-light-brand-primary dark:bg-dark-brand-primary": type === "success",
      "bg-light-source-error dark:bg-dark-source-error": type === "error",
    }
  );

  const iconClasses = clsx({
    "fill-light-source-error dark:fill-dark-source-error": type === "error",
    "fill-light-brand-primary dark:fill-dark-brand-primary": type === "success",
  });

  const isPresent = useIsPresent();

  return (
    <motion.div
      style={{
        position: isPresent ? "static" : "fixed",
      }}
      className={classes}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
    >
      <div className="flex items-center justify-center rounded-full h-9 w-9 bg-light-source-white dark:bg-dark-source-white">
        <Icon name="check" className={iconClasses} />
      </div>

      <AnimatePresence>
        {isPresent && (
          <motion.span
            className="items-center inline-block overflow-hidden text-lg font-normal leading-none text-light-source-white dark:text-dark-source-white whitespace-nowrap"
            initial={{ width: 0, opacity: 0, paddingLeft: 0, paddingRight: 0 }}
            animate={{
              width: "auto",
              opacity: 1,
              paddingLeft: 12,
              paddingRight: 20,
            }}
            exit={{
              opacity: 0,
              width: 0,
              paddingLeft: 0,
              paddingRight: 0,
              transition: { duration: 0.1 },
            }}
            transition={{
              delay: 0.15,
              duration: 0.1,
              type: "tween",
            }}
          >
            {message}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Toast;
