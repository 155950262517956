import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const FileIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8333 1.67981C10.8333 1.65789 10.8333 1.64693 10.8324 1.62706C10.8127 1.21558 10.4509 0.853896 10.0394 0.834429C10.0195 0.83349 10.013 0.833492 10 0.833497H7.29888C6.6281 0.833488 6.07446 0.83348 5.62348 0.870327C5.15507 0.908598 4.72448 0.990735 4.32003 1.19681C3.69283 1.51639 3.18289 2.02632 2.86331 2.65353C2.65724 3.05798 2.5751 3.48856 2.53683 3.95698C2.49998 4.40797 2.49999 4.9616 2.5 5.63239V14.3679C2.49999 15.0387 2.49998 15.5924 2.53683 16.0434C2.5751 16.5118 2.65724 16.9424 2.86331 17.3468C3.18289 17.974 3.69283 18.4839 4.32003 18.8035C4.72448 19.0096 5.15507 19.0917 5.62348 19.13C6.07447 19.1669 6.6281 19.1668 7.2989 19.1668H12.7011C13.3719 19.1668 13.9255 19.1669 14.3765 19.13C14.8449 19.0917 15.2755 19.0096 15.68 18.8035C16.3072 18.4839 16.8171 17.974 17.1367 17.3468C17.3428 16.9424 17.4249 16.5118 17.4632 16.0434C17.5 15.5924 17.5 15.0387 17.5 14.3679V8.33345C17.5 8.3205 17.5 8.31403 17.4991 8.29418C17.4796 7.88265 17.1179 7.52076 16.7064 7.50112C16.6865 7.50017 16.6756 7.50017 16.6538 7.50017L12.9734 7.50017C12.7631 7.5002 12.5583 7.50022 12.3848 7.48605C12.1943 7.47049 11.9695 7.43376 11.7434 7.31852C11.4298 7.15873 11.1748 6.90376 11.015 6.59016C10.8997 6.36397 10.863 6.13919 10.8475 5.9487C10.8333 5.77523 10.8333 5.57041 10.8333 5.36009L10.8333 1.67981ZM6.66667 10.8335C6.20643 10.8335 5.83333 11.2066 5.83333 11.6668C5.83333 12.1271 6.20643 12.5002 6.66667 12.5002H13.3333C13.7936 12.5002 14.1667 12.1271 14.1667 11.6668C14.1667 11.2066 13.7936 10.8335 13.3333 10.8335H6.66667ZM6.66667 14.1668C6.20643 14.1668 5.83333 14.5399 5.83333 15.0002C5.83333 15.4604 6.20643 15.8335 6.66667 15.8335H11.6667C12.1269 15.8335 12.5 15.4604 12.5 15.0002C12.5 14.5399 12.1269 14.1668 11.6667 14.1668H6.66667Z"
      />
      <path d="M15.7566 5.83349C16.0013 5.83349 16.1237 5.8335 16.2239 5.77206C16.3654 5.68529 16.45 5.48086 16.4112 5.31944C16.3838 5.20514 16.304 5.12544 16.1444 4.96603L13.3675 2.1891C13.2081 2.02953 13.1284 1.94974 13.0141 1.92227C12.8526 1.88347 12.6482 1.96808 12.5614 2.10961C12.5 2.20983 12.5 2.33218 12.5 2.57686L12.5 5.1668C12.5 5.40014 12.5 5.51682 12.5454 5.60595C12.5854 5.68435 12.6491 5.74809 12.7275 5.78804C12.8166 5.83345 12.9333 5.83345 13.1667 5.83346L15.7566 5.83349Z" />
    </svg>
  );
};

export default FileIcon;
