import { deleteTokens } from "@/utils/auth";
import Icon from "../Icons";

const LogoutButton = () => {
  const handleClick = () => {
    deleteTokens();

    window.location.reload();
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      className="p-2 rounded-md hover:bg-light-source-container-error hover:dark:bg-dark-source-container-error flex gap-3 items-center w-max"
    >
      <Icon name="logout" />
      <span className="text-light-source-error dark:text-dark-source-error font-normal text-base">
        Logout
      </span>
    </button>
  );
};

export default LogoutButton;
