import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const SearchIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16666 1.66663C5.02452 1.66663 1.66666 5.02449 1.66666 9.16663C1.66666 13.3088 5.02452 16.6666 9.16666 16.6666C10.9375 16.6666 12.565 16.0529 13.8481 15.0265L16.9107 18.0892C17.2362 18.4147 17.7638 18.4147 18.0892 18.0892C18.4147 17.7638 18.4147 17.2361 18.0892 16.9107L15.0266 13.848C16.0529 12.565 16.6667 10.9375 16.6667 9.16663C16.6667 5.02449 13.3088 1.66663 9.16666 1.66663ZM3.33332 9.16663C3.33332 5.94496 5.945 3.33329 9.16666 3.33329C12.3883 3.33329 15 5.94496 15 9.16663C15 12.3883 12.3883 15 9.16666 15C5.945 15 3.33332 12.3883 3.33332 9.16663Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default SearchIcon;