import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const EngIcon = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1953_4644)">
        <path d="M24 0H0V24H24V0Z" fill="#F0F0F0" />
        <path
          d="M0 14.25H9.75V24H14.25V14.25H24V9.75H14.25V0H9.75V9.75H0V14.25Z"
          fill="#D80027"
        />
        <path
          d="M19.0747 15.6523L24.0003 20.578V15.6523H19.0747Z"
          fill="#0052B4"
        />
        <path
          d="M15.6523 15.6523L24.0002 24.0002V21.6395L18.013 15.6523H15.6523Z"
          fill="#0052B4"
        />
        <path
          d="M21.7766 24.0004L15.6523 17.8755V24.0004H21.7766Z"
          fill="#0052B4"
        />
        <path
          d="M15.6523 15.6523L24.0002 24.0002V21.6395L18.013 15.6523H15.6523Z"
          fill="#F0F0F0"
        />
        <path
          d="M15.6523 15.6523L24.0002 24.0002V21.6395L18.013 15.6523H15.6523Z"
          fill="#D80027"
        />
        <path d="M3.76416 15.6523L0 19.4165V15.6523H3.76416Z" fill="#0052B4" />
        <path d="M8.34761 16.7139V24H1.06201L8.34761 16.7139Z" fill="#0052B4" />
        <path
          d="M5.98706 15.6523L0 21.6395V24L8.34769 15.6523H5.98706Z"
          fill="#D80027"
        />
        <path d="M4.92562 8.34799L0 3.42236V8.34799H4.92562Z" fill="#0052B4" />
        <path
          d="M8.34783 8.34783L0 0V2.36062L5.9872 8.34783H8.34783Z"
          fill="#0052B4"
        />
        <path d="M2.22363 0L8.3479 6.12483V0H2.22363Z" fill="#0052B4" />
        <path
          d="M8.34783 8.34783L0 0V2.36062L5.9872 8.34783H8.34783Z"
          fill="#F0F0F0"
        />
        <path
          d="M8.34783 8.34783L0 0V2.36062L5.9872 8.34783H8.34783Z"
          fill="#D80027"
        />
        <path d="M20.2358 8.34765L24 4.5835V8.34765H20.2358Z" fill="#0052B4" />
        <path d="M15.6523 7.28616V0H22.9379L15.6523 7.28616Z" fill="#0052B4" />
        <path
          d="M18.013 8.34769L24 2.36058V0L15.6523 8.34769H18.013Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1953_4644">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EngIcon;
