import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const UsersIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0263 12.3973C15.1411 11.9515 15.5954 11.6832 16.0411 11.7979C17.838 12.2604 19.1667 13.8907 19.1667 15.8333V17.5C19.1667 17.9602 18.7936 18.3333 18.3334 18.3333C17.8731 18.3333 17.5 17.9602 17.5 17.5V15.8333C17.5 14.6695 16.7041 13.6896 15.6256 13.412C15.1799 13.2973 14.9116 12.843 15.0263 12.3973Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1442 2.42958C12.3169 2.00297 12.8028 1.79712 13.2294 1.96981C14.7548 2.5873 15.8334 4.08335 15.8334 5.83329C15.8334 7.58324 14.7548 9.07929 13.2294 9.69678C12.8028 9.86946 12.3169 9.66362 12.1442 9.23701C11.9716 8.8104 12.1774 8.32457 12.604 8.15188C13.5217 7.78039 14.1667 6.88116 14.1667 5.83329C14.1667 4.78542 13.5217 3.8862 12.604 3.51471C12.1774 3.34202 11.9716 2.85619 12.1442 2.42958Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98248 11.6666C7.27196 11.667 8.56144 11.667 9.85091 11.6666C10.7598 11.6663 11.3799 11.6661 11.9118 11.8086C13.3497 12.1939 14.4728 13.317 14.8581 14.7549C15.0499 15.471 14.9944 16.2363 15.0002 16.97C15.0009 17.0591 15.0023 17.2393 14.9574 17.4068C14.8419 17.8382 14.5049 18.1751 14.0736 18.2907C13.906 18.3356 13.7259 18.3342 13.6368 18.3334C9.82427 18.303 6.00913 18.303 2.19662 18.3334C2.10753 18.3342 1.92735 18.3356 1.75984 18.2907C1.32848 18.1751 0.991541 17.8382 0.875957 17.4068C0.831072 17.2393 0.832508 17.0591 0.833219 16.97C0.839054 16.2381 0.783768 15.4698 0.97534 14.7549C1.36062 13.317 2.48373 12.1939 3.92162 11.8086C4.45346 11.6661 5.07363 11.6663 5.98248 11.6666Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75003 5.83329C3.75003 3.53211 5.61551 1.66663 7.9167 1.66663C10.2179 1.66663 12.0834 3.53211 12.0834 5.83329C12.0834 8.13448 10.2179 9.99996 7.9167 9.99996C5.61551 9.99996 3.75003 8.13448 3.75003 5.83329Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default UsersIcon;
