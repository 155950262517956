import { useLayoutEffect, useState } from "react";

const root = window.document.documentElement;

const useThemeSwitcher = () => {
  const [theme, setTheme] = useState(localStorage.theme);

  useLayoutEffect(() => {
    if (!root.classList.contains(theme)) {
      root.classList.remove("light", "dark");
      root.classList.add(theme);
    }

  }, []);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");

    const nextTheme = theme === "light" ? "dark" : "light";

    root.classList.remove(theme);
    root.classList.add(nextTheme);

    localStorage.setItem("theme", nextTheme);
  };

  return {
    theme,
    toggleTheme,
  };
};

export default useThemeSwitcher;
