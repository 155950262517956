import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const EyeIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.33332 9.99998C8.33332 9.0795 9.07951 8.33331 9.99998 8.33331C10.9205 8.33331 11.6667 9.0795 11.6667 9.99998C11.6667 10.9205 10.9205 11.6666 9.99998 11.6666C9.07951 11.6666 8.33332 10.9205 8.33332 9.99998Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 3.33331C7.61526 3.33331 5.67111 4.38725 4.23061 5.5895C2.79207 6.79013 1.80579 8.17855 1.31184 8.96069L1.29177 8.99234C1.19375 9.14662 1.06649 9.34694 1.00215 9.62762C0.950209 9.85425 0.950209 10.1457 1.00215 10.3723C1.06649 10.653 1.19375 10.8533 1.29177 11.0076L1.31184 11.0393C1.80579 11.8214 2.79207 13.2098 4.23061 14.4105C5.67111 15.6127 7.61526 16.6666 9.99999 16.6666C12.3847 16.6666 14.3289 15.6127 15.7694 14.4105C17.2079 13.2098 18.1942 11.8214 18.6881 11.0393L18.7082 11.0076C18.8062 10.8533 18.9335 10.653 18.9978 10.3723C19.0498 10.1457 19.0498 9.85425 18.9978 9.62762C18.9335 9.34694 18.8062 9.14662 18.7082 8.99233L18.6881 8.96069C18.1942 8.17855 17.2079 6.79013 15.7694 5.5895C14.3289 4.38725 12.3847 3.33331 9.99999 3.33331ZM9.99998 6.66665C8.15904 6.66665 6.66665 8.15903 6.66665 9.99998C6.66665 11.8409 8.15904 13.3333 9.99998 13.3333C11.8409 13.3333 13.3333 11.8409 13.3333 9.99998C13.3333 8.15903 11.8409 6.66665 9.99998 6.66665Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default EyeIcon;