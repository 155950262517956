import useAuth from "@/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

type NonProtectedRouteProps = {
  children: JSX.Element;
  redirectPath?: string;
};

function NonProtectedRoute({
  redirectPath = "/",
  children,
}: NonProtectedRouteProps) {
  const { token } = useAuth();
  const location = useLocation();

  if (token) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return children;
}

export default NonProtectedRoute;
