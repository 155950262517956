import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ChevronLeftIcon = (props: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#13171A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4714 3.52861C10.7318 3.78896 10.7318 4.21107 10.4714 4.47141L6.94281 8.00001L10.4714 11.5286C10.7318 11.789 10.7318 12.2111 10.4714 12.4714C10.2111 12.7318 9.78894 12.7318 9.52859 12.4714L5.52859 8.47141C5.26824 8.21107 5.26824 7.78896 5.52859 7.52861L9.52859 3.52861C9.78894 3.26826 10.2111 3.26826 10.4714 3.52861Z"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
