import useAuth from "@/hooks/useAuth";
import { UserAuthority } from "@/providers/AuthProvider";
import { getAccessToken, isTokenExpired } from "@/utils/auth";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

type ProtectedRouteProps = {
  children: JSX.Element;
  redirectPath?: string;
  allowedRoles?: UserAuthority[];
};

function ProtectedRoute({
  redirectPath = "/login",
  children,
  allowedRoles,
}: ProtectedRouteProps) {
  const localAccessToken = getAccessToken();
  const { token, logout, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const isAccessExpired = isTokenExpired(localAccessToken);

    if (isAccessExpired) {
      logout();
    }
  }, [localAccessToken, logout]);

  if (!token) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  if (allowedRoles && user && !allowedRoles.includes(user.authority)) {
    return <Navigate to="/not-found" replace state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;
