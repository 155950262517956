import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

type AuthLayoutProps = PropsWithChildren & {};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const location = useLocation();
  const alt =
    location.pathname === "/login" ? "login wallpaper" : "register wallpaper";
  const wallpaper =
    location.pathname === "/login"
      ? "/images/login-wallpaper.png"
      : "/images/register-wallpaper.png";

  return (
    <div className="flex h-screen">
      <div className="flex-1 relative">
        <div className="absolute top-[104px] left-[136px]">
          <img src="/images/logo-transparent.svg" alt="logo" />
        </div>
        <div className="absolute bottom-[104px] left-[136px] flex gap-12">
          <a
            href="#"
            className="text-lg text-light-source-white dark:text-dark-source-white"
          >
            Məxfilik siyasəti
          </a>
          <a
            href="#"
            className="text-lg text-light-source-white dark:text-dark-source-white"
          >
            Qaydalar və razılaşmalar
          </a>
        </div>
        <img src={wallpaper} alt={alt} className="object-cover w-full h-full" />
      </div>
      <div className="bg-light-background-primary dark:bg-dark-background-primary pl-[88px] pr-[136px]">
        <div className="w-[344px] h-full">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
