import {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import EyeIcon from "@/components/Icons/Eye";
import ClosedEye from "@/components/Icons/ClosedEye";
import { trimString } from "@/utils/input";
import Icon, { IconName } from "@/components/Icons";

export type InputProps = PropsWithChildren &
  Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & {
    labelClassName?: string;
    inputWrapperClassName?: string;
    icon?: IconName;
    helper?: string;
    size?: "sm" | "md";
    error?:
      | string
      | boolean
      | {
          message?: string;
        };
  };

const Input = forwardRef<HTMLInputElement, InputProps>(function (props, ref) {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    placeholder,
    value,
    type,
    disabled = false,
    error,
    icon,
    className,
    labelClassName,
    inputWrapperClassName,
    helper,
    defaultValue,
    size = "md",
    onChange,
    onBlur,
    onFocus,
    ...restProps
  } = props;
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const labelClasses = clsx(
    "absolute z-0 transition-all ease-linear duration-200 peer-focus:text-sm text-light-utility-medium cursor-text dark:text-dark-utility-medium peer-focus:top-2 peer-focus:translate-y-0",
    {
      "translate-y-0 top-2 text-sm": hasValue,
      "-translate-y-1/2 top-1/2 text-lg": !hasValue,
    },
    labelClassName
  );

  const inputWrapperClasses = clsx(
    "relative rounded-xl w-[344px] box-border flex items-center border border-transparent transition-all w-full",
    {
      "focus-within:border-light-brand-primary dark:focus-within:border-dark-brand-primary bg-light-surface-medium dark:bg-dark-surface-medium focus-within:bg-light-surface-high dark:focus-within:bg-dark-surface-high":
        !error,
      "bg-light-source-container-error dark:bg-dark-source-container-error":
        !!error,
      "p-4 h-14": size === "md",
      "px-4 py-2": size === "sm",
      "opacity-50": disabled,
    },
    inputWrapperClassName
  );

  const inputClasses = clsx(
    "box-border relative z-10 flex-1 text-lg bg-transparent outline-none autofill:bg-transparent peer text-light-utility-high dark:text-dark-utility-high disabled:opacity-60 z-[0]",
    {
      "top-2": size === "md",
    }
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value && !hasValue) {
        setHasValue(true);
      }

      if (!value && hasValue) {
        setHasValue(false);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [hasValue, onChange]
  );

  useEffect(() => {
    const value = inputRef.current?.value;

    if (value && !hasValue) {
      setHasValue(true);
    }
  }, [inputRef.current?.value, hasValue, setHasValue]);

  return (
    <div className={className}>
      <div className={inputWrapperClasses}>
        {icon && (
          <div className="mr-3">
            <Icon name={icon} />
          </div>
        )}
        <input
          aria-autocomplete="none"
          ref={inputRef}
          className={inputClasses}
          autoComplete="off"
          type={type === "password" && showPassword ? "text" : type}
          disabled={disabled}
          defaultValue={defaultValue}
          {...restProps}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            trimString(e, "both");
            setFocused(false);
            onBlur?.(e);
          }}
          onChange={(e) => {
            trimString(e, "start");
            handleChange(e);
          }}
          {...(size === "sm" &&
            placeholder && {
              placeholder,
            })}
        />

        {type === "password" &&
          (!showPassword ? (
            <EyeIcon
              className="cursor-pointer stroke-light-utility-medium dark:stroke-dark-utility-medium"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          ) : (
            <ClosedEye
              className="cursor-pointer stroke-light-utility-medium dark:stroke-dark-utility-medium"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          ))}

        {placeholder && size !== "sm" && (
          <label
            className={labelClasses}
            onClick={() => inputRef.current?.focus()}
          >
            {placeholder}
          </label>
        )}
      </div>
      {focused && helper && !error && (
        <div className="px-4">
          <span className="text-sm font-normal text-light-utility-medium dark:text-dark-utility-medium">
            {helper}
          </span>
        </div>
      )}
      {error && (
        <div className="px-4">
          <span className="text-sm font-normal text-light-source-error dark:text-dark-source-error">
            {typeof error === "object"
              ? error.message
              : "This field is required"}
          </span>
        </div>
      )}
    </div>
  );
});

export default Input;
