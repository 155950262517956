import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ChevronDoubleRightIcon = (props: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52861 4.19526C3.78896 3.93491 4.21107 3.93491 4.47141 4.19526L7.80475 7.5286C8.0651 7.78894 8.0651 8.21106 7.80475 8.4714L4.47141 11.8047C4.21107 12.0651 3.78896 12.0651 3.52861 11.8047C3.26826 11.5444 3.26826 11.1223 3.52861 10.8619L6.39053 8L3.52861 5.13807C3.26826 4.87772 3.26826 4.45561 3.52861 4.19526Z"
        fill="#13171A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19527 4.19526C8.45562 3.93491 8.87773 3.93491 9.13808 4.19526L12.4714 7.5286C12.7318 7.78894 12.7318 8.21106 12.4714 8.4714L9.13808 11.8047C8.87773 12.0651 8.45562 12.0651 8.19527 11.8047C7.93492 11.5444 7.93492 11.1223 8.19527 10.8619L11.0572 8L8.19527 5.13807C7.93492 4.87772 7.93492 4.45561 8.19527 4.19526Z"
        fill="#13171A"
      />
    </svg>
  );
};

export default ChevronDoubleRightIcon;
