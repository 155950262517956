import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <div className="flex flex-col items-stretch justify-center h-full">
      <LoginForm />
    </div>
  );
};

export default Login;
