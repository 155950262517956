import useThemeSwitcher from "@/hooks/useThemeSwitcher";
import DemoButton from "../Form/Button/DemoButton";
import IconButton from "../Form/Button/IconButton";
import Input from "../Form/Input";

const Header = () => {
  const { toggleTheme } = useThemeSwitcher();

  return (
    <header className="flex justify-between items-center py-[18px]">
      <div>
        <Input placeholder="Axtarish" />
      </div>

      <div className="flex gap-3">
        <DemoButton />
        <IconButton
          icon="moon"
          size="lg"
          onClick={() => {
            toggleTheme();
          }}
        />
        <IconButton icon="bell" size="lg" />
      </div>
    </header>
  );
};

export default Header;
