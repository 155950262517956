import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const FilterIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16666 9.99999C4.16666 9.53975 4.53975 9.16666 4.99999 9.16666H15C15.4602 9.16666 15.8333 9.53975 15.8333 9.99999C15.8333 10.4602 15.4602 10.8333 15 10.8333H4.99999C4.53975 10.8333 4.16666 10.4602 4.16666 9.99999Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66666 4.99999C1.66666 4.53975 2.03975 4.16666 2.49999 4.16666H17.5C17.9602 4.16666 18.3333 4.53975 18.3333 4.99999C18.3333 5.46023 17.9602 5.83332 17.5 5.83332H2.49999C2.03975 5.83332 1.66666 5.46023 1.66666 4.99999Z"
        fill="#62717C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66666 15C6.66666 14.5398 7.03975 14.1667 7.49999 14.1667H12.5C12.9602 14.1667 13.3333 14.5398 13.3333 15C13.3333 15.4602 12.9602 15.8333 12.5 15.8333H7.49999C7.03975 15.8333 6.66666 15.4602 6.66666 15Z"
        fill="#62717C"
      />
    </svg>
  );
};

export default FilterIcon;
