import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const ThreeDots = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-1.6 -1.6 19.20 19.20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      {...props}
    >
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeWidth="0.192"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>{" "}
      </g>
    </svg>
  );
};

export default ThreeDots;
