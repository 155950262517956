import useAuth from "@/hooks/useAuth";
import NavigationItem from "./NavigationItem";
import { isSponsor, isSuperAdmin } from "@/utils/auth";

const Navigation = () => {
  // TODO::Implement superadmin
  const { user } = useAuth();
  const superAdmin = isSuperAdmin(user);
  const sponsor = isSponsor(user);

  return (
    <div>
      <div className="pb-8">
        <img src="/images/logo.svg" alt="Main logo" />
      </div>

      <div className="flex flex-col">
        {superAdmin && <NavigationItem to="/" name="Ana səhifə" icon="home" />}
        {superAdmin && (
          <NavigationItem to="/users" name="İstifadəçilər" icon="users" />
        )}
        {superAdmin && <NavigationItem to="/files" name="Files" icon="users" />}
        {superAdmin && (
          <NavigationItem to="/stories" name="Stories" icon="users" />
        )}
        {superAdmin && (
          <NavigationItem to="/reports" name="Reports" icon="users" />
        )}
        {superAdmin && (
          <NavigationItem
            to="/admin-settings"
            name="Tənzimləmələr"
            icon="settings"
          />
        )}
        {superAdmin && (
          <NavigationItem to="/versions" name="Versiyalar" icon="settings" />
        )}
        {sponsor && (
          <>
            <NavigationItem to="/create" name="Yarat" icon="plus-circle" />
            <NavigationItem to="/balance" name="Balans" icon="currency" />
          </>
        )}
        <NavigationItem to="/settings" name="Settings" icon="settings" />
      </div>
    </div>
  );
};

export default Navigation;
