import { ChangeEvent } from "react";

const onlyDigitsRegex = /^[1-9]*$/;

export const clearCharacters = (e: ChangeEvent<HTMLInputElement>) => {
  if (!onlyDigitsRegex.test(e.target.value)) {
    e.target.value = e.target.value.replace(/\D|^0+/g, "");
  }
};

export const trimString = (
  e: ChangeEvent<HTMLInputElement>,
  trim: "start" | "end" | "both" = "both"
) => {
  if (trim === "start") {
    e.target.value = e.target.value.trimStart();
  } else if (trim === "end") {
    e.target.value = e.target.value.trimEnd();
  } else {
    e.target.value = e.target.value.trim();
  }
};
