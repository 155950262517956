import clsx from "clsx";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className?: string;
}>;

const Container = (props: Props) => {
  const className = clsx(
    "lg:w-[848px] lg:max-w-[848px] mx-auto h-full",
    props.className
  );

  return <div className={className}>{props.children}</div>;
};

export default Container;
